import { computed, onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props



const { reveal } = useScrollTriggerAnimation();
const tpl_btnWrapper = templateRef("tpl_btnWrapper");
const tpl_btn = templateRef("tpl_btn");
const tpl_bgImg = templateRef("tpl_bgImg");

const xPos = computed(() => {
  let pos = "right-0 bg-right-top";

  if (props.dataProvider.title) {
    if (
      (props.dataProvider.title.align === "start" &&
        props.dataProvider.title.image) ||
      (props.dataProvider.title.align === "end" &&
        !props.dataProvider.title.image)
    ) {
      pos = "left-0 bg-left-top";
    }
  }

  return pos;
});

onMounted(() => {
  requestAnimationFrame(() => {
    if (props.dataProvider.bgImage) {
      gsap.from(tpl_bgImg.value, {
        scrollTrigger: {
          trigger: tpl_bgImg.value,
          start: "top 50%",
          end: "bottom 95%",
          scrub: 1.2,
        },
        ease: "sine.inOut",
        maskSize: "0% 0%",
        force3D: true,
      });
    }

    if (
      props.dataProvider.link &&
      props.dataProvider.link.title &&
      props.dataProvider.link.to
    ) {
      reveal(tpl_btn.value.$el, tpl_btnWrapper.value, "right", {
        fade: true,
      });
    }
  });
});

return { __sfc: true,props, reveal, tpl_btnWrapper, tpl_btn, tpl_bgImg, xPos }
}

}