import { ref, onMounted } from "vue";
import { templateRef, useMediaQuery } from "@vueuse/core";
import useTailwindCss from "/composables/useTailwindCss";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  media: {
    type: Object,
    required: false,
  },
  mediaSizes: {
    type: Object,
    required: false,
  },
  tag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1;
    },
    default: "h1",
  },
  title: {
    type: String,
    required: true,
  },
  align: {
    type: String,
    required: false,
    validator: function (value) {
      return ["start", "end"].indexOf(value) !== -1;
    },
    default: "start",
  },
},
  setup(__props) {

const props = __props



const tpl_root = templateRef("tpl_root");
const tpl_mediaWrapper = templateRef("tpl_mediaWrapper");
const tpl_title = templateRef("tpl_title");
const aspectRatio = ref(null);
const { screens } = useTailwindCss();
const isMDScreen = useMediaQuery(`(min-width: ${screens.md.min})`);
const { reveal } = useScrollTriggerAnimation();

function setAspectRatio(lines) {
  aspectRatio.value = lines >= 3 ? "4 / 5" : "1 / 1";
}

onMounted(() => {
  requestAnimationFrame(() => {
    reveal(tpl_title.value, tpl_title.value, "right", {
      opacity: true,
    });

    if (props.media && tpl_mediaWrapper.value) {
      gsap.killTweensOf(tpl_mediaWrapper.value.$el);
      gsap.from(tpl_mediaWrapper.value.$el, {
        scrollTrigger: {
          trigger: tpl_root.value,
          start: "top 70%",
          toggleActions: "restart none none reverse",
        },
        duration: 1,
        ease: "sine.inOut",
        opacity: 0,
        force3D: "auto",
        transformOrigin: "bottom",
        scale: 0.92,
        xPercent: props.align === "start" ? -20 : 20,
        force3D: "auto",
      });
    }
  });
});

return { __sfc: true,props, tpl_root, tpl_mediaWrapper, tpl_title, aspectRatio, screens, isMDScreen, reveal, setAspectRatio }
}

}