import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b01af9d2&scoped=true&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=b01af9d2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b01af9d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TxtTitleHero: require('/var/www/html/fantini/frontend/components/txt/title/hero/index.vue').default,Txt: require('/var/www/html/fantini/frontend/components/txt/index.vue').default,BtnLink: require('/var/www/html/fantini/frontend/components/btn/link/index.vue').default,GridCss: require('/var/www/html/fantini/frontend/components/grid/css/index.vue').default,Block: require('/var/www/html/fantini/frontend/components/block/index.vue').default})
