import { ref, onMounted, watch, nextTick } from "vue";
import { useWindowSize, debouncedWatch, templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  tag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(value) !== -1;
    },
    default: "h1",
  },
  title: {
    type: String,
    required: true,
  },
  align: {
    type: String,
    required: false,
    validator: function (value) {
      return ["start", "end"].indexOf(value) !== -1;
    },
    default: "start",
  },
  linesCallback: {
    type: Function,
    required: false,
    default: function () {},
  },
},
  setup(__props) {

const props = __props



const tpl_mediaWrapper = templateRef("tpl_mediaWrapper");
const tpl_titleAlternate = templateRef("tpl_titleAlternate");
const tpl_title = templateRef("tpl_title");
const wideText = ref(false);
const aspectRatio = ref(null);
const { reveal, unveilText } = useScrollTriggerAnimation();
const { width } = useWindowSize();

function setAspectRatio() {
  props.linesCallback(
    tpl_titleAlternate.value.getElementsByClassName("splitext-line").length
  );
}

function setFontSize() {
  let maxCharsInLine = 0;
  let lines = tpl_titleAlternate.value.getElementsByClassName("splitext-line");

  for (let i = 0, len = lines.length; i < len; i++) {
    let words = lines[i].getElementsByClassName("splitext-word");
    let charsInLine = 0;

    for (let j = 0, l = words.length; j < l; j++) {
      charsInLine += words[j].textContent.length;
    }

    if (charsInLine > maxCharsInLine) maxCharsInLine = charsInLine;
  }

  let fontSize =
    parseInt(
      tpl_titleAlternate.value.getBoundingClientRect().width /
        (maxCharsInLine * 0.5)
    ) * 0.8;
  if (fontSize > 160) fontSize = 160;

  gsap.set(tpl_titleAlternate.value, { fontSize });
}

function setTextOffset() {
  let counter = 0;
  const { x: titleX, width: titleWidth } =
    tpl_titleAlternate.value.getBoundingClientRect();
  const safeTitleWidth =
    titleWidth * (props.media && props.media.type ? 0.75 : 1);

  const lines =
    tpl_titleAlternate.value.getElementsByClassName("splitext-line");

  let linePaddingSize = 0;

  for (let i = 0, len = lines.length; i < len; i++) {
    const { x: lineX } = lines[i].getBoundingClientRect();
    let words = lines[i].getElementsByClassName("splitext-word");
    const { x: targetX, width: targetWidth } =
      words[
        props.align === "start" ? words.length - 1 : 0
      ].getBoundingClientRect();

    let maxLinePaddingSize = 0;

    if (props.align === "start") {
      maxLinePaddingSize = safeTitleWidth - (targetX + targetWidth) + lineX;
    } else {
      maxLinePaddingSize = targetX - lineX;
    }

    switch (counter) {
      case 0:
        linePaddingSize =
          i > 0
            ? safeTitleWidth * 0.05 + Math.random(safeTitleWidth * 0.05)
            : 0;
        break;

      case 1:
        linePaddingSize +=
          safeTitleWidth * 0.25 + Math.random(safeTitleWidth * 0.05);
        break;

      case 2:
        linePaddingSize -=
          safeTitleWidth * 0.07 + Math.random(safeTitleWidth * 0.03);
        break;

      case 3:
        linePaddingSize +=
          safeTitleWidth * 0.15 + Math.random(safeTitleWidth * 0.05);
        break;

      case 4:
        linePaddingSize +=
          safeTitleWidth * 0.08 + Math.random(safeTitleWidth * 0.04);
        break;
    }

    linePaddingSize = parseInt(Math.max(linePaddingSize, 0));

    //This avoids heavy bg image overlap and line break
    linePaddingSize = parseInt(Math.min(linePaddingSize, maxLinePaddingSize));

    gsap.set(lines[i], {
      paddingLeft: props.align === "start" ? linePaddingSize : 0,
      paddingRight: props.align === "end" ? linePaddingSize : 0,
    });

    counter++;
    if (counter === 5) counter = 0;
  }
}

watch(width, () => {
  wideText.value = false;
});

debouncedWatch(
  width,
  () => {
    setFontSize();
    setAspectRatio();

    nextTick(() => {
      wideText.value = true;
    });
  },
  { debounce: 300 }
);

watch(wideText, (newVal, oldVal) => {
  nextTick(() => {
    if (newVal === true) setTextOffset();
  });
});

onMounted(() => {
  unveilText(tpl_titleAlternate.value, tpl_titleAlternate.value, "bottom", {
    textTarget: "words",
    intensity: "strong",
    stagger: "wide",
    duration: "normal",
  });

  reveal(tpl_title.value, tpl_title.value, "right", {
    intensity: "strong",
    opacity: true,
  });

  nextTick(() => {
    setFontSize();
    setAspectRatio();
    wideText.value = true;
  });

  if (props.media && tpl_mediaWrapper.value) {
    gsap.killTweensOf(tpl_mediaWrapper.value.$el);
    requestAnimationFrame(() => {
      gsap.from(tpl_mediaWrapper.value.$el, {
        scrollTrigger: {
          trigger: tpl_titleAlternate.value,
          start: "top 70%",
          toggleActions: "restart none none reverse",
        },
        duration: 1,
        ease: "sine.inOut",
        opacity: 0,
        force3D: "auto",
        transformOrigin: "bottom",
        scale: 0.92,
        xPercent: props.align === "start" ? -20 : 20,
        force3D: "auto",
      });
    });
  }
});

return { __sfc: true,props, tpl_mediaWrapper, tpl_titleAlternate, tpl_title, wideText, aspectRatio, reveal, unveilText, width, setAspectRatio, setFontSize, setTextOffset }
}

}